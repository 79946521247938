<template>
    <div class="directory-listing-item" v-if="directory">
        <div>
            <div class="title">{{ directory.business_name }}</div>
            <div class="image-container text-center my-2">
                <ImageItem
                    :src="imgsrc + directory.business_photo"
                    :alt="directory.business_name"
                    class="img-fluid w-100"
                    style="max-height: 200px; object-fit: cover;"
                />
            </div>

            <p class="description">{{ shortText(directory.business_description) }}</p>
        </div>
        <div>
            <router-link
                class="primary-button d-inline m-0 btn-primary"
                :to="{
                    name: 'directory_listing_single',
                    params: { categorySlug: categorySlug, id: directory.id },
                }"
                >View Details</router-link
            >
        </div>
    </div>
</template>

<script>
import ImageItem from "../utils/ImageItem.vue"

export default {
    components: { ImageItem },
    data() {
        return {
            imgsrc: "/public",
        }
    },
    props: {
        categorySlug: {
            type: String,
            default: "",
        },
        directory: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        shortText(text, length = 50) {
            return text > 50 ? text.slice(0, length) + "..." : text
        },
    },
}
</script>

<style lang="scss" scoped>
.directory-listing-item {
    border: 1px solid #bbb;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 15px;
    padding-bottom: 20px;
    min-height: 180px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
        font-size: 1.1em;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
    }

    .description {
        padding: 10px 0;
        color: #666;
    }
}
</style>
