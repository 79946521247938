<template>
    <div class="contianer p-md-5 p-3">
        <PageLoader :isLoading="isLoading" />
        <DirectoryListingHeader
            v-if="category"
            :title="`Directory Listings in ${category.name || '...'}`"
        />
        <router-link class="text-primary" :to="{ name: 'directory_listing' }"
            >Back to Directory Listings</router-link
        >
        <div class="row mt-4">
            <div class="col-md-4" v-for="directory in directories" :key="directory.id">
                <DirectoryListingItem :categorySlug="category.slug" :directory="directory" />
            </div>
        </div>

        <div v-if="noDirectories && !isLoading">No directories found in this category</div>
        <Paginator
            :page="page"
            :itemsPerPage="itemsPerPage"
            :totalResults="totalResults"
            @update="pageUpdate"
        />
    </div>
</template>

<script>
import DirectoryListingItem from "./DirectoryListingItem.vue"
import DirectoryListingHeader from "./DirectoryListingHeader.vue"
import PageLoader from "../Loader.vue"
import Paginator from "../utils/Paginator.vue"
import scrollTop from "../utils/scrollTop"
import request from "../../apis/request"

export default {
    components: {
        DirectoryListingItem,
        DirectoryListingHeader,
        PageLoader,
        Paginator,
    },
    data() {
        return {
            isLoading: true,
            directories: [],
            category: {},
            page: 1,
            totalResults: 0,
            itemsPerPage: 10,
        }
    },
    async mounted() {
        scrollTop()
    },
    methods: {
        async fetchDirectoryCategory() {
            const res = await request.get(
                `/directories/categories/${this.$route.params.categorySlug}`
            )
            this.category = res.data.result
        },
        async fetchDirectoryListings() {
            this.isLoading = true
            try {
                const res = await request.get(
                    `/directories?category=${this.category.id}&page=${this.page}&limit=${this.itemsPerPage}`
                )
                this.directories = res.data.result
                this.totalResults = res.data.total_results
            } catch (err) {
                console.log("Error in fetching directory listings.")
            }
            this.isLoading = false
        },
        async pageUpdate(pageData) {
            const { page, totalPages } = pageData
            if (page) {
                this.page = page
                if (!this.category.id) {
                    await this.fetchDirectoryCategory()
                }

                await this.fetchDirectoryListings()
            }
        },
    },
    computed: {
        noDirectories() {
            return !this.isLoading && this.directories.length === 0
        },
    },
}
</script>

<style></style>
